import { useEffect, useState } from "react";
// import { connectWallet,
//   getCurrentWalletConnected
//  } from "./utils/interact.js";
 import { createAlchemyWeb3 } from "@alch/alchemy-web3";
 import env from "react-dotenv";
 import { isMobile } from 'react-device-detect';
 
 require('dotenv').config();



 const apiKey = env.REACT_APP_TOKEN_APIKEY;

const Minter = (props) => {

  //State variables
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [url, setURL] = useState("");
  const [validWallet, setValidWallet] = useState("");

  var browser = "";
  if(isMobile){
    var browser = "TRUE";
  }
  else{
    var browser = "FALSE";
  }
  
  
  const web3 = createAlchemyWeb3(
    `https://eth-mainnet.alchemyapi.io/v2/${apiKey}`,
  );

  

  useEffect(async () => { 
    const {address, status} = await getCurrentWalletConnected();
    setWallet(address)
    setStatus(status); 

    //const walletString = await verifyWallet();
    //printRetString(walletString);
    addWalletListener();
  }, []);

  const connectWalletPressed = async () => { 
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);

    verifyWallet();

  };

  


  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const obj = {
          status: <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" class="heading mobile">Verifying...</h1>,
          address: addressArray[0],
        };
        
        return obj;
      } catch (err) {
        return {
          address: "",
          status: "😥 " + JSON.stringify(err.message),
        };
      }
    } else {
      return {
        address: "",
        status: (
          <span>
            <p>
              {" "}
              🦊{" "}
              <a target="_blank" style="color: white !important;" href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          </span>
        ),
      };
    }
  };

  const getCurrentWalletConnected = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: "eth_accounts",
        });
        if (addressArray.length > 0) {

         
          return {
            address: addressArray[0],
            status: <div class="redirect-section">
            <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" class="heading mobile">Verify Ownership</h1>
            <p class="paragraph">If you own an Invisible Friend, you are eligible to enter the shop, click the button below to connect your MetaMask wallet so we can verify ownership. Once verified, you'll be granted access to the Invisible Friends store!</p>
            <button id="walletButton" onClick={connectWalletPressed}>
                {walletAddress.length > 0 ? (
                  "Connected: " +
                  String(walletAddress).substring(0, 6) +
                  "..." +
                  String(walletAddress).substring(38)
                ) : (
                  <span>Connect Wallet</span>
                )}
              </button>
          </div>,
          };
          
          

        } else {
          return {
            address: "",
            status: <div class="redirect-section">
            <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" class="heading mobile">Verify Ownership</h1>
            <p class="paragraph">If you own an Invisible Friend, you are eligible to enter the shop, click the button below to connect your MetaMask wallet so we can verify ownership. Once verified, you'll be granted access to the Invisible Friends store!</p>
            <button id="walletButton" onClick={connectWalletPressed}>
                {walletAddress.length > 0 ? (
                  "Connected: " +
                  String(walletAddress).substring(0, 6) +
                  "..." +
                  String(walletAddress).substring(38)
                ) : (
                  <span>Connect Wallet</span>
                )}
              </button>
          </div>,
          };
        }
      } catch (err) {
        return {
          address: "",
          status: "😥 " + JSON.stringify(err.message),
        };
      }
    } else {
        if(isMobile){
          return {
            address: "",
            status: (
              <div class="redirect-section">
                <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" class="heading mobile">Browsing on mobile?</h1>
                <p class="paragraph">Click the button below to open this webpage in the Metamask Browser, or copy and paste the URL of this webpage into the Metamask Browser</p>
                <a id="walletButton" href="https://metamask.app.link/dapp/invisible-friends-tdm-gate.herokuapp.com/" >
                    Open in Metamask
                  </a>
              </div>
            ),
          };
        }
        else{
          return {
            address: "",
            status: (
              <div class="redirect-section">
                <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" class="heading mobile">No Metamask extension found</h1>
                <p class="paragraph">Click the button below to download Metamask to supporting browsers</p>
                <a id="walletButton" href="https://metamask.io/download.html" >
                    Download Metamask
                  </a>
              </div>
            ),
          };
        }
      }
  };

  const onMintPressed = async () => { //TODO: implement
    
  };

  const verifyWallet = async () => {
    const {address, status} = await getCurrentWalletConnected();

    console.log(" Address: "+address.length);

    if ( address.length == 0 ){
      console.log("TRUE SHOW HOME");
      setWallet("");
          setStatus(
            <div class="redirect-section">
              <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" class="heading mobile">Verify Ownership</h1>
              <p class="paragraph">If you own an Invisible Friend, you are eligible to enter the shop, click the button below to connect your MetaMask wallet so we can verify ownership. Once verified, you'll be granted access to the Invisible Friends store!</p>

              <button id="walletButton" onClick={connectWalletPressed}>
                {walletAddress.length > 0 ? (
                  "Connected: " +
                  String(walletAddress).substring(0, 6) +
                  "..." +
                  String(walletAddress).substring(38)
                ) : (
                  <span>Connect Wallet</span>
                )}
              </button>

            </div>
        );
        return false;
    }
    
    else{




      // const whitelist = ["2824",
      // "1140",
      // "483",
      // "3257",
      // "3624",
      // "1975",
      // "4672",
      // "279",
      // "2187",
      // "1348",
      // "2645",
      // "3292",
      // "4896",
      // "864",
      // "3965",
      // "2863",
      // "3533",
      // "1675",
      // "3451",
      // "180",
      // "1899",
      // "439",
      // "2820",
      // "2826",
      // "3326",
      // "2114",
      // "1959",
      // "2169",
      // "421",
      // "4410",
      // "3466",
      // "4967",
      // "2940",
      // "2377",
      // "1335",
      // "3841",
      // "821",
      // "3702",
      // "3737",
      // "3701",
      // "3319",
      // "1020",
      // "4911",
      // "1064",
      // "2159",
      // "629",
      // "3563",
      // "3497",
      // "3173",
      // "2170",
      // "4740",
      // "2104",
      // "4647",
      // "2520",
      // "3366",
      // "4640",
      // "1",
      // "398",
      // "2748",
      // "1834",
      // "4084",
      // "3099",
      // "3880",
      // "2651",
      // "977",
      // "133",
      // "871",
      // "2627",
      // "1323",
      // "4688",
      // "264",
      // "4066",
      // "1483",
      // "71",
      // "327",
      // "1072",
      // "3483",
      // "2835",
      // "1251",
      // "1125",
      // "4207",
      // "2554",
      // "4005",
      // "1212",
      // "2919",
      // "916",
      // "1178",
      // "2671",
      // "1596",
      // "1514",
      // "3884",
      // "1942",
      // "433",
      // "3496",
      // "97",
      // "2269",
      // "3140",
      // "2120",
      // "921",
      // "2815",
      // "3499",
      // "4538",
      // "3234",
      // "1631",
      // "880",
      // "2407",
      // "3080",
      // "4764",
      // "3436",
      // "3231",
      // "2322",
      // "453",
      // "2614",
      // "866",
      // "2083",
      // "1885",
      // "547",
      // "2692",
      // "3874",
      // "3893",
      // "4245",
      // "3416",
      // "3318",
      // "4547",
      // "2523",
      // "2069",
      // "4784",
      // "4842",
      // "2123",
      // "1309",
      // "1585",
      // "4777",
      // "1942",
      // "128",
      // "3493",
      // "1721",
      // "2237",
      // "3147",
      // "2276",
      // "1395",
      // "1465",
      // "379",
      // "682",
      // "2372",
      // "3006",
      // "1702",
      // "4576",
      // "1868",
      // "1125",
      // "1978"
      // ];





       //var toCheck = tests.ownedNfts[0].tokenUri.gateway.replace('https://ipfs.io/ipfs/QmarGRwVKPaCe2s5QSSTMEdbYDwKxFz6bAn58YZPPcWc7k/','');

       //console.log("Does it contain: "+toCheck+" answer: " + whitelist.includes(toCheck));
    
      const nfts = await web3.alchemy.getNfts({owner: address, contractAddresses: ["0x59468516a8259058bad1ca5f8f4bff190d30e066","0x49c36afa15c7fdbd57ce3d61d80f39b6615a76ef"]});

       var foundNFT = false;
    
    


    if ( address == "0x2231b0188dad7349695dc84b8fe5d1bee5e79cfe" || address == "0x282672eda77623459c49066c1531dde8edb5bd75" ){

      foundNFT = true;
    }



    for (const nft of nfts.ownedNfts) {
      
      if ( nft.contract.address == "0x59468516a8259058bad1ca5f8f4bff190d30e066" || nft.contract.address == "0x49c36afa15c7fdbd57ce3d61d80f39b6615a76ef" ){
        
          foundNFT = true;
        
      }
      

    }

    
   

    

    if(foundNFT == true){
      allowWallet();

    }
    else{
      denyWallet();

    }

    return foundNFT;
  }
  }

  function printRetString (walletString){
    setStatus(JSON.stringify(walletString));

  }

  function allowWallet(){
    setStatus(
      <div>
        <div class="redirect-section">
            <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" class="heading mobile">Verified!</h1>
            <p class="paragraph">Congratulations! You're eligible to access the shop. Click the button below to enter.</p>
            
        </div>
        <div class="button-container"><a href="https://shop.invisiblefriends.io/collections/holders-collection/?key=42357678923" class="button-2 w-button">Proceed to Store</a></div>
        
      </div>
    );

  }
 
  function denyWallet(){
    setStatus(
      <div class="redirect-section">
            <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" class="heading mobile">Sorry!</h1>
            <p class="paragraph">We were not able to locate and Invisible Friend in your wallet, open Metamask and connect a valid wallet</p>
            
        </div>
    );
  }
  
  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus(<h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" class="heading mobile">Verifying...</h1>);
          
          verifyWallet();
          
          
        } else {
          setWallet("");
          setStatus(
            <div class="redirect-section">
              <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" class="heading mobile">Verify Ownership</h1>
              <p class="paragraph">If you own an Invisible Friend, you are eligible to enter the shop, click the button below to connect your MetaMask wallet so we can verify ownership. Once verified, you'll be granted access to the Invisible Friends store!</p>

              <button id="walletButton" onClick={connectWalletPressed}>
                {walletAddress.length > 0 ? (
                  "Connected: " +
                  String(walletAddress).substring(0, 6) +
                  "..." +
                  String(walletAddress).substring(38)
                ) : (
                  <span>Connect Wallet</span>
                )}
              </button>

            </div>
        );
        }
      });
    } else {
      if(isMobile){
        setStatus(
          <div class="redirect-section">
                <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" class="heading mobile">Browsing on mobile?</h1>
                <p class="paragraph">Click the button below to open this webpage in the Metamask Browser, or copy and paste this URL into the MetaMask Browser</p>
                <a id="walletButton" href="https://metamask.app.link/dapp/invisible-friends-tdm-gate.herokuapp.com/" >
                    Open in Metamask
                  </a>
              </div>
        );
      }
      else{
        setStatus(
          <div class="redirect-section">
                <h1 data-w-id="aa3fb2eb-a63f-4694-1b36-4534ed4f5a82" class="heading mobile">No Metamask extension found</h1>
                <p class="paragraph">Click the button below to download Metamask to supporting browsers</p>
                <a id="walletButton" href="https://metamask.io/download.html" >
                    Download Metamask
                  </a>
              </div>
        );
      }
    }
  }

  return (
    <div className="Minter">
      

      
      <div id="status">
        {status}
      </div>

      

    </div>
  );
};

export default Minter;
